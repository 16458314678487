+
<template>
  <div ref="fdFileUpload" class="fd-file-upload">
    <label class="label mb-1">
      {{ label }}<span style="color: red" v-if="required">*</span>
    </label>

    <div class="upload">
      <button class="btn main upload--btn">
        <span v-if="!isLoading">
          <i class="fas fa-upload mr-1"></i>
          Upload a file
        </span>
        <!-- isLoading Spinner -->
        <span v-else>
          <spinner
            color="white"
            size="14px"
            class="d-inline-block mr-1"
          ></spinner>
          Uploading
        </span>

        <input
          v-if="!isLoading"
          type="file"
          value=""
          :multiple="multiple"
          @change="fileSelected"
          :accept="accept"
          :required="computedInputRequired"
        />
      </button>
      <!-- ======================== Addtional info ========================= -->
      <div class="mt-1">
        <!-- Max file number -->
        <p class="maximum">
          Max: {{ multiple ? maxFile : "1" }} Files, {{ maxSize }} MB each
        </p>
        <p class="allowed-ext">
          Allowed:
          <span
            v-for="(ext, index) in allowed"
            :key="index"
            class="allowed-ext--ext"
            >.{{ ext }}</span
          >
        </p>
      </div>
    </div>
    <!-- TODO: Failed Upload -->

    <!-- ======================== Uploaded Files ========================= -->
    <div class="uploaded-files mt-2">
      <div v-if="value.length > 0">
        <p v-if="multiple" class="uploaded-total text-right px-1">
          {{ value.length }} file<span v-if="value.length > 1">s</span>
        </p>
        <div
          class="file d-flex align-items-center"
          v-for="(file, index) in value"
          :key="`file${index}`"
        >
          <p class="file--name">
            <span v-tooltip="file.fileName">
              {{ getFileName(file.fileName) }}
            </span>
          </p>
          <download-button
            @download="(isDownloading) => downloadFile(file, isDownloading)"
          ></download-button>
          <fd-button class="file--remove ml-1" @click="removeFile(file)">
            <i class="fas fa-times"></i>
          </fd-button>
        </div>
      </div>
      <div v-else class="no-files">No files available</div>
    </div>
  </div>
</template>

<script>
import { fileUpload as fileUploadAPI } from "@/api";
import { elipsisMiddle, getFileExtension } from "@/utils/string";
import { getFileURL } from "@/utils/url";
import { downloadFile } from "@/utils/download";

const KB = 1024;
const MB = KB * 1024;

export default {
  components: {
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner"),
    DownloadButton: () => import("./DownloadButton")
  },
  mixins: [],
  props: {
    value: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: "File Upload"
    },
    required: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    maxFile: {
      type: Number,
      default: 1
    },
    maxSize: {
      // In MB
      type: Number,
      default: 5
    },
    downloadEnabled: {
      type: Boolean,
      default: true
    },
    accept: {
      type: String,
      default:
        "application/msword,application/vnd.ms-excel,application/vnd.ms-powerpoint,application/pdf"
    },
    allowed: {
      type: Array,
      default: () => [
        "pdf",
        "doc",
        "docx",
        "odt",
        "xls",
        "xlsx",
        "ods",
        "ppt",
        "pptx"
      ]
    }
  },
  data: function () {
    return {
      isLoading: false,
      uploadError: false,
      isDownloading: false
    };
  },
  computed: {
    computedInputRequired() {
      if (this.required && this.value.length <= 0) {
        return true;
      }

      return false;
    },
    renderingData() {
      // Null checking purpose. (If null, then convert to empty array)
      if (this.value == null) {
        return [];
      } else {
        let temp = this.value.concat();
        for (let x = 0; x < temp.length; x++) {
          if (temp[x] == null) {
            temp.splice(x, 1);
          }
        }
        return temp;
      }
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    getFileName(name) {
      return elipsisMiddle(name);
    },
    setLoading(boolean) {
      this.isLoading = boolean;
      this.$emit("isLoading", boolean);
    },
    async fileSelected(e) {
      let files = e.target.files;
      for (let file of files) {
        this.setLoading(true);

        if (!this.validateMaxFile(this.renderingData.length + 1)) {
          break;
        }
        if (!this.validateFormat(file)) {
          continue;
        }
        if (!this.validateMaxSize(file)) {
          continue;
        }

        try {
          // Upload the file to server
          await this.uploadFile(file);
          continue;
        } catch (error) {
          // console.warn(error);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text:
              "Something went wrong with the file upload. Please try again later."
          });
          this.$emit("error", error, file.name);
          continue;
        }
      }
      this.setLoading(false);
      e.target.value = null;
    },
    async uploadFile(file) {
      try {
        let data = await fileUploadAPI.uploadFile(file);
        let newArray = [...this.value, data];
        this.$emit("input", newArray);
      } catch (error) {
        throw error;
      }
    },
    async downloadFile(file, setIsDownloading) {
      setIsDownloading(true);
      let URL = getFileURL(file);
      let label = `${file.fileName}`;
      let ext = getFileExtension(file.fileName);

      try {
        downloadFile(URL, label, ext);
        setIsDownloading(false);
      } catch (error) {
        setIsDownloading(false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Download Failed",
          text: "Attempt to download has failed. Please try again later."
        });
      }
    },
    removeFile(fileObj) {
      // Create an array without the removed file
      let newArray = this.value.filter((file) => {
        return file.fileName !== fileObj.fileName;
      });

      // Update v-model with latest file array data
      this.$emit("input", newArray);
      // On delete, the file object will be returned
      this.$emit("delete", fileObj);
    },

    /**
     * Checks if still can add file.
     * @return Boolean
     */
    isAddFileAvailable() {
      if (this.multiple) {
        return this.renderingData.length < this.maxFile ? true : false;
      } else {
        return this.renderingData.length < 1;
      }
    },

    validateMaxFile(length) {
      if (length > this.maxFile) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: `You can only upload ${this.maxFile} file. The rest will be automatically ignored.`
        });
        return false;
      }
      return true;
    },

    validateMaxSize(file) {
      if (file.size > this.maxSize * MB) {
        this.$notify({
          group: "alert",
          type: "error",
          title: `File size limit exceeded (${this.maxSize}MB)`,
          text: `"${elipsisMiddle(file.name)}" has exceeded file size limit.`
        });

        return false;
      }
      return true;
    },

    validateFormat(file) {
      let appendedSlash = this.allowed.map((ext) => {
        return "\\" + ext;
      });
      let regexAllowed = appendedSlash.join("|");
      const allowedExtensions = new RegExp(`${regexAllowed}$`, "i");
      let extension = file.name.substr(file.name.lastIndexOf(".") + 1);
      if (!allowedExtensions.exec(extension)) {
        this.$notify({
          group: "alert",
          type: "error",
          title: `${elipsisMiddle(file.name)}: File format is not allowed`,
          text: `Only ${this.allowed.join(", ")} is allowed`
        });

        return false;
      }
      return true;
    }
  }
};
</script>

<style lang="scss">
.fd-file-upload {
  max-width: 500px;
  border: solid 1px #ddd;
  border-radius: 5px;
  padding: 12px;

  .upload {
    .upload--btn {
      position: relative;
      input:hover {
        cursor: pointer;
      }
    }
    input {
      position: absolute;
      cursor: pointer;
      height: 100%;
      width: 100%;
      opacity: 0;
      bottom: 0;
      left: 0;
      top: 0;
    }
    .maximum,
    .uploaded-total,
    .allowed-ext {
      font-size: 12px;
      color: #666;
      .allowed-ext--ext {
        &:not(:last-child)::after {
          content: " ";
        }
      }
    }
  }

  .uploaded-files {
    .file {
      padding: 8px;
      margin-bottom: 4px;
      background: #fafafa;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;

      .file--name {
        width: 100%;
      }

      .file--remove {
        align-self: end;
        &:hover {
          background: $color-danger;
          color: white;
        }
      }
    }
  }
  .no-files {
    background: #fafafa;
    border-radius: 5px;
    font-weight: normal;
    color: #666;
    padding: 12px;
  }
}
</style>
